import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Tastatursnarveier" />
    <div className="longtext keyboard-shortcuts">
      <h2>Tastatursnarveier</h2>

      <h3>Oppgaver</h3>
      <table>
        <tr>
          <td>Egendefinert</td>
          <td>
            En global snarvei som raskt lar deg opprette en oppgave, uansett
            hvilken app du har i fokus.{" "}
            <Link to="/help/helper">Se hvordan du setter opp denne her.</Link>
          </td>
        </tr>
        <tr>
          <td>Command-N</td>
          <td>Ny oppgave</td>
        </tr>
        <tr>
          <td>Command-L</td>
          <td>Flytt markerte oppgaver</td>
        </tr>
        <tr>
          <td>Command-D</td>
          <td>Toggle fullføring av oppgave</td>
        </tr>
        <tr>
          <td>Command-Shift-D</td>
          <td>Deleger oppgaver (krever at listen er delt)</td>
        </tr>
        <tr>
          <td>Shift + Klikk</td>
          <td>Marker flere oppgaver</td>
        </tr>
        <tr>
          <td>Command + Klikk</td>
          <td>Marker flere oppgaver</td>
        </tr>
        <tr>
          <td>Enter</td>
          <td>Åpne markert oppgave for endring</td>
        </tr>
        <tr>
          <td>Backspace</td>
          <td>Slett markerte oppgaver</td>
        </tr>
        <tr>
          <td>ESC</td>
          <td>Lukk / lagre oppgave / fjern markering</td>
        </tr>

        <tr>
          <td>Control-Command-1</td>
          <td>Flytt markerte oppgaver til Innboksen</td>
        </tr>
        <tr>
          <td>Control-Command-2</td>
          <td>Flytt markerte oppgaver til I dag</td>
        </tr>
        <tr>
          <td>Control-Command-3</td>
          <td>Flytt markerte oppgaver til i morgen</td>
        </tr>
        <tr>
          <td>Control-Command-4</td>
          <td>Flytt markerte oppgaver til neste-listen</td>
        </tr>
        <tr>
          <td>Control-Command-5</td>
          <td>Flytt markerte oppgaver til venter</td>
        </tr>
        <tr>
          <td>Control-Command-6</td>
          <td>Flytt markerte oppgaver til rugekassen</td>
        </tr>
        <tr>
          <td>Control-Command-9</td>
          <td>Flytt markerte oppgaver til i biblioteket</td>
        </tr>
      </table>

      <h3>Lister</h3>
      <table>
        <tr>
          <td>Command-Shift-L</td>
          <td>Ny liste</td>
        </tr>
        <tr>
          <td>Command-+</td>
          <td>Vis Produktivitet</td>
        </tr>
        <tr>
          <td>Command-1</td>
          <td>Vis Innboksen</td>
        </tr>
        <tr>
          <td>Command-2</td>
          <td>Vis I dag</td>
        </tr>
        <tr>
          <td>Command-3</td>
          <td>Vis Fremtidig</td>
        </tr>
        <tr>
          <td>Command-4</td>
          <td>Vis Neste</td>
        </tr>
        <tr>
          <td>Command-5</td>
          <td>Vis Venter</td>
        </tr>
        <tr>
          <td>Command-6</td>
          <td>Vis Rugekassen</td>
        </tr>
        <tr>
          <td>Command-7</td>
          <td>Vis Mål</td>
        </tr>
        <tr>
          <td>Command-8</td>
          <td>Vis Fullførte</td>
        </tr>
        <tr>
          <td>Command-9</td>
          <td>Vis Biblioteket</td>
        </tr>
      </table>

      <h3>Braindump</h3>
      <table>
        <tr>
          <td>Command-B</td>
          <td>
            Start braindump. Kan brukes for å skrive ned mange oppgaver
            samtidig, en pr. linje
          </td>
        </tr>
        <tr>
          <td>Command-Enter</td>
          <td>Fullfør braindump</td>
        </tr>
      </table>

      <h3>Gjennomganger</h3>
      <table>
        <tr>
          <td>Command-Pil høyre</td>
          <td>Neste steg</td>
        </tr>
        <tr>
          <td>Command-Pil venstre</td>
          <td>Forrige steg</td>
        </tr>
      </table>

      <h3>Andre snarveier</h3>
      <table>
        <tr>
          <td>Command-F</td>
          <td>Søk / hopp til nylig brukt liste</td>
        </tr>
        <tr>
          <td>Command-,</td>
          <td>Vis innstillinger</td>
        </tr>
        <tr>
          <td>Command-?</td>
          <td>Vis hjelp</td>
        </tr>
        <tr>
          <td>Command-Shift-N</td>
          <td>Nytt vindu</td>
        </tr>
        <tr>
          <td>Command-W</td>
          <td>Lukk vindu</td>
        </tr>
        <tr>
          <td>Command-Shift-S</td>
          <td>Vis/skjul sidebar</td>
        </tr>
      </table>

      <h2>Se også</h2>
      <ul>
        <li>
          <Link to="/help/helper">Globale snarveier og MenteHelper</Link>
        </li>
        <li>
          <Link to="/help/">Spørsmål og svar</Link>
        </li>
      </ul>
    </div>
  </Layout>
)

export default Page
